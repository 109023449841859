import { render, staticRenderFns } from "./PopupWindow.vue?vue&type=template&id=3b253e22&scoped=true&"
import script from "./PopupWindow.vue?vue&type=script&lang=js&"
export * from "./PopupWindow.vue?vue&type=script&lang=js&"
import style0 from "./PopupWindow.vue?vue&type=style&index=0&id=3b253e22&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b253e22",
  null
  
)

component.options.__file = "PopupWindow.vue"
export default component.exports