<template>
  <div class="managingPeople hp50 bbox">
     <!-- <div style="height:100vh;"> -->
    <!-- 卡片视图 -->
      <div class="chooseTop">
        <div>
          <el-radio-group v-model="windowType" @change="radioChange">
            <el-radio label="1">图文</el-radio>
            <el-radio label="2">视频</el-radio>
          </el-radio-group>
          
        </div>
        <div style="margin-left: 40px;">
          是否展示：
          <el-switch
            v-model="isShow"
            active-color="#13ce66"
            inactive-color="#ff4949" 
            @change="switchChange"
            >
          </el-switch>
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="图文" name="1" style="height:75vh;">
           <div>
               <quill-editor
                   class="ql-editor"
                   v-model="textWindow.content"
                   ref="myQuillEditor"
                   :options="editorOption"
                 >
               </quill-editor>
          </div>

          <div class="priBtn">
            <el-button type="primary" @click="buttonEditor">提交</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="视频" name="2">
          <div class="tabPanetop">
            <el-checkbox style="margin-right: 5px;" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
            <el-button type="primary" @click="batchDelete">批量删除</el-button>
            <el-button type="primary" @click="videoBtn"><i class="el-icon-upload2"></i> 上传</el-button>
          </div>
          <div>
        <el-table
          border
          ref="table"
          :data="videoWindowList"
          tooltip-effect="dark"
          style="width: 100%"
          :height="height"
          @selection-change="handleSelectionChange"
        >
        <el-table-column
            label="选中删除"
            align="center"
            show-overflow-tooltip
            v-if="checkAll"
            type="selection"
          >
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            label="视频名称"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.title }}</template>
          </el-table-column>
          <el-table-column label="视频" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <video class="videoPopop" controls :src="scope.row.fileUrl"></video>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.createDate }}</template>
          </el-table-column>
        </el-table>
      </div>
        </el-tab-pane>
      </el-tabs>

      <el-dialog
      title="视频上传"
      :visible.sync="videoDialog"
      width="30%">
      <el-form ref="videoForm" :model="textWindow" label-width="120px" :key="timer">
        <el-form-item label="视频标题：">
          <el-input v-model="textWindow.title" placeholder="请输入视频标题"></el-input>
        </el-form-item>
        <el-form-item label="视频上传：" prop="fileUrl">
              <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="false"
                :on-success="videoSuccess" :limit="1" :on-remove="removeVideo">
                <video v-if="textWindow.fileUrl" class="avatarVideo" :src="textWindow.fileUrl"></video>
                <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
         <el-button @click="closeVideo">取 消</el-button>
         <el-button type="primary" @click="submitVideo">确 定</el-button>
       </span>
    </el-dialog>

    <!-- 富文本框图片 -->
    <el-upload  v-show="false" :action="$uploadURL" :show-file-list="false"
       :on-success="quillSuccess" >
      <el-button size="small" type="primary" id="quill-image">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

 import 'quill/dist/quill.core.css'
 import 'quill/dist/quill.snow.css'
 import 'quill/dist/quill.bubble.css'
export default {
  data() {
        return {
		  // 富文本编辑器配置
      editorOption: {
        modules: {
          toolbar:{
            container:[
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            // [{ font: ['songti'] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ['clean'], // 清除文本格式
            ['image', 'video'] // 链接、图片、视频
          ],
        // },
        handlers:{
          image:function(value){
            if(value){
              //调用elementUi上传组件替换富文本原生图片上传
              document.getElementById('quill-image').click();
            }else{
              this.quill.format('image',false)
            }
          }
        }
      }
      },
    },
          activeName:'1',
          videoList:[],   // 视频
          videoDialog:false,
          textWindow:{
            title:'',
            fileUrl:'',
            content:'',
            windowType:'1'
          },
          videoWindowList:[],  // 视频
          windowType:'1',
          isShow:true,
          height: window.innerHeight - 200, // 表格高度
          timer:'',
          checkAll:false,  
          idList:[]    // 选中的id
	      }
	  	},
  components: {
    quillEditor
  },
  created() {
    this.getPicturelList()
  },
  mounted() {
    // 元素加载完毕之后富文本不聚焦
    this.$refs.myQuillEditor.quill.enable(false)
    // 一秒以后聚焦
    setTimeout(() => {
      this.$refs.myQuillEditor.quill.enable(true)
    }, 1000);
    
  },
  methods:{
    // 富文本图片上传
    quillSuccess(res, file) { 
      console.log(file);
      // console.log('上传');
      let quill=this.$refs.myQuillEditor.quill
      let length=quill.getSelection().index
      quill.insertEmbed(length,"image",file.response.data.url)
      quill.setSelection(length+1)
    },
      // tab点击
      handleClick(tab) {
        // console.log(tab);
        if(tab.name == 1) {
          this.getPicturelList()
        }else {
          this.getVideoList()
        }
      },

      // 图文获取
      getPicturelList() {
        var that = this
        that.$http.post('/popupWindow/queryDetail',{windowType:'1'}).then(function(response) {
          console.log(response);
          if(response.data.code == 200) {
            that.textWindow.content = response.data.data.textWindow.content
            // that.$notify.success({
            //   title: "提示",
            //   message: "获取数据成功",
            //   showClose: true,
            // });
            if(response.data.data.isShow == '1') {
              that.isShow = true
            }else {
              that.isShow = false
            }
            // console.log(that.isShow);
            that.windowType = response.data.data.windowType
          }else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
      },

      // 视频获取
      getVideoList() {
        var that = this
        that.$http.post('/popupWindow/queryDetail',{windowType:'2'}).then(function(response) {
          console.log(response);
          if(response.data.code == 200) {
            // that.$notify.success({
            //   title: "提示",
            //   message: "获取数据成功",
            //   showClose: true,
            // });
            if(response.data.data.isShow == '1') {
              that.isShow = true
            }else {
              that.isShow = false
            }
            that.windowType = response.data.data.windowType
            that.videoWindowList = response.data.data.videoWindowList
          
          }else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
      },

      // 富文本内容提交
      buttonEditor() {
        var that  = this
        that.textWindow.windowType = '1'
        that.textWindow.title = ''
        that.textWindow.fileUrl = ''
        that.$http.post('/popupWindow/saveDetailList', that.textWindow).then(function(response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.showDialog = false;
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
      },

      async batchDelete(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("/popupWindowDetail/deleteBatch", {idList:this.idList}).then(function (response) {
          if (response.data.code == 200) {
            that.checkAll = false
            that.$notify.success({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
            that.getVideoList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
      }
    },

      // 上传按钮
      videoBtn() {
        this.videoDialog = true
        this.checkAll = false 
      },

      // 视频上传成功
      videoSuccess(val) {
        console.log(val);
        this.textWindow.fileUrl = val.data.url
        this.timer = new Date().getTime()
      },

      // 移除视频
      removeVideo(val) {
        console.log(val);
      },

      // 确定按钮
      submitVideo() {
        var that  = this
        that.videoDialog = false
        that.textWindow.windowType = '2'
        that.textWindow.content = ''
        that.$http.post('/popupWindow/saveDetailList', that.textWindow).then(function(response) {
          // console.log(response);
          if(response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
            that.getVideoList()
            that.closeVideo()
          }else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        // console.log(that.$refs.videoForm);
      },

      // 关闭表单
      closeVideo() {
        this.videoDialog = false
        this.textWindow = {
            title:'',
            fileUrl:'',
            content:'',
            windowType:1
          }
      },

      // 单选
      radioChange(val) {
        // console.log(val);
        var that = this
        if(val == '1'){
          that.$http.post('/popupWindow/save',{windowType:'1',queryType:'1'}).then(function(response) {
          console.log(response);
        })
        }else {
          that.$http.post('/popupWindow/save',{windowType:'2',queryType:'1'}).then(function(response) {
          console.log(response);
        })
        }
      },

      // 是否展示
      switchChange(val) {
        console.log(val);
        var that  = this
        if(val == true){
          that.$http.post('/popupWindow/save',{queryType:'2',isShow:'1'}).then(function(response) {
          console.log(response);
        })
        }else {
          that.$http.post('/popupWindow/save',{queryType:'2',isShow:'0'}).then(function(response) {
          console.log(response);
        })
        }
      },

      // 全选选中批量删除
      handleCheckAllChange(val) {
        // this.checkedVideo = val ? this.videoes : [];
        if(val) {
          this.checkOne = true
        }
      },

      // 单个选中
      handleSelectionChange(val) {
        console.log(val);
        let videoTabList = []
        this.idList = []
        videoTabList = val
        videoTabList.forEach(item => {
          this.idList.push(item.id)
        })
        console.log(this.idList);
      }

  }
};
</script>

<style lang="less" scoped>
.managingPeople {
  .el-card {
    // height: calc(100vh - 114px);
    position: relative;
  }
  .priBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    margin-right: 20px;
  }
  .ql-editor{
    // height: 200px;
    height: 65vh;
  }
  .tabPanetop{
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
}
.iconSize{
  width: 100%;
  margin-top: 30px;
  .el-icon-upload2{
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 80px;
  color: #1fa0ff;
 }
  p{
    text-align: center;
    color: #1fa0ff;
    font-size: 20px;
  }
}

.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatarVideo {
  width: 180px;
  height: 150px;
  display: block;
}

.chooseTop{
  display: flex;
  margin-bottom: 15px;
}

.videoBox{
  display: flex;
  // justify-content: space-around;
  flex-wrap: wrap;
  word-wrap:break-word; 
  overflow:hidden;
  margin-top: 20px;
  .videoShow{
  height: 300px;
  width: 600px;
  margin-right: 20px;
  .videoTi{
    height: 40px;
    line-height: 39px;
    margin: 0;
    text-align: center;
    font-weight: 700;
  }
  .video{
    width: 120px;
    height: 260px;
  }
}
}
}

.videoPopop{
  width: 180px;
  height: 120px;
}

::v-deep.ql-snow .ql-editor img {
  max-width: 30%;
}
 /deep/ .ql-container.ql-snow .ql-editor {
  height: 100%;
 }
</style>
